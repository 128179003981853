/* eslint-disable import/no-duplicates */
import '@/ui-library/utils/app';

import {
  useUser,
  useUserManager,
} from '@/ui-library/composables/useUserManager';
import { initializeApp } from '@/ui-library/utils/app';
import Routes from '@/ui-library/utils/routes';
import App from '@/ui-sandbox/App.vue';

initializeApp(
  {
    el: '#app',
    template: '<App />',
    components: { App },
  },
  {
    routerConfig: { mode: 'history', routes: [] },
  }
);
const redirectUser = async () => {
  // This function happens before the navigational guards are set up, so we need
  // to manually initialize the user manager.
  const { initialize } = useUserManager();
  const res = await initialize();
  // user manager is redirecting, no reason to proceed farther
  if (res?.redirectingToLogin) {
    return;
  }

  // Redirect the user to the appropriate page based on their user type.
  const { isAdmin, isContributor, isPartner } = useUser();

  // If the user is not of a valid user type, send them to the logout page because we don't know where else to send them.
  let location = Routes.authentication.logout();
  if (isAdmin.value) {
    location = Routes.admin.home();
  } else if (isContributor.value) {
    location = Routes.jobs.dashboard();
  } else if (isPartner.value) {
    location = Routes.projects.home();
  }

  window.location.assign(location);
};
redirectUser();
